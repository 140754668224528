import { AnimatePresence, motion } from "framer-motion";
import type { PropsWithChildren } from "react";
import { createContext, useContext, useEffect, useState } from "react";
import { createPortal } from "react-dom";

type ToastContext = {
  toast: (toastOptions: ToastOptions) => void;
};

type ToastOptions = {
  message: string;
  delay?: number;
};

export interface Toast extends ToastOptions {
  id: string;
}

export const ToastContext = createContext<ToastContext>({ toast: () => {} });

export function ToastProvider({ children }: PropsWithChildren) {
  const [toasts, setToast] = useState<Toast[]>([]);
  const [renderToastContainer, setRenderToastContainer] = useState(false);

  function toast(toastOptions: ToastOptions) {
    const id = Math.random().toString(36).substring(7);

    setToast((prev) => [...prev, { id, ...toastOptions }]);

    setTimeout(() => {
      setToast((prev) => prev.filter((t) => t.id !== id));
    }, toastOptions.delay || 2000);
  }
  useEffect(() => {
    setRenderToastContainer(true);
  }, []);

  return (
    <ToastContext.Provider value={{ toast }}>
      {children}
      {renderToastContainer &&
        createPortal(
          <div className="fixed bottom-24 inset-x-0 flex flex-col gap-2 items-center pointer-events-none z-50 ">
            <AnimatePresence mode="sync">
              {toasts.map((toast) => (
                <motion.div
                  key={toast.id}
                  className="pointer-events-auto flex items-center  gap-1 p-2 font-semibold text-xs bg-neutral-700 text-white rounded-md"
                  initial={{ opacity: 0, scale: 0.3 }}
                  animate={{ opacity: 1, scale: 1 }}
                  exit={{ opacity: 0, height: 0, scale: 0 }}
                  onClick={() =>
                    setToast((prev) => prev.filter((t) => t.id !== toast.id))
                  }
                >
                  {toast.message}
                </motion.div>
              ))}
            </AnimatePresence>
          </div>,
          document.body,
        )}
    </ToastContext.Provider>
  );
}

export function useToast() {
  const { toast } = useContext(ToastContext);
  return toast;
}
